import * as THREE from 'three'
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';

function randomPointOnSphere(radiusMin, radiusMax) {
    const range = radiusMax - radiusMin
    const r = radiusMin + Math.random() * range
    const u = Math.random()
    const v = Math.random()
    const theta = 2 * Math.PI * u
    const phi = Math.acos(2 * v - 1)
    const x = r * Math.sin(phi) * Math.cos(theta)
    const y = r * Math.sin(phi) * Math.sin(theta)
    const z = r * Math.cos(phi)
    return new THREE.Vector3(x, y, z)
}

function createTestGroup() {
    const group = new THREE.Group();
    group.position.set(0, 2, 0);
    group.rotation.set(Math.PI * 0.25, 0, 0);

    const c1 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        new THREE.MeshBasicMaterial({ color: 0x00ff00 })
    )
    const c2 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        new THREE.MeshBasicMaterial({ color: 0x0000ff })
    )
    const c3 = new THREE.Mesh(
        new THREE.BoxGeometry(1, 1, 1),
        new THREE.MeshBasicMaterial({ color: 0x00ffff })
    )
    c1.position.x = -2
    c2.position.x = 0
    c3.position.x = +2
    group.add(c1)
    group.add(c2)
    group.add(c3)

    return group
}

function createRandomMesh() {
    //
    // Attribute Buffers
    //
    const triCount = 500
    const vtxCount = triCount * 3
    const floatCount = vtxCount * 3
    const posArray = new Float32Array(floatCount)
    for (let i = 0; i < floatCount; i++) {
        posArray[i] = Math.random() - 0.5
    }
    const posAttr = new THREE.BufferAttribute(posArray, 3)
    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute('position', posAttr);
    const material = new THREE.MeshBasicMaterial({
        color: 0x0088ff,
        wireframe: true
    })
    const mesh = new THREE.Mesh(geometry, material)
    return mesh;
}

function textureSetup(texture) {
    texture.colorSpace = THREE.SRGBColorSpace
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
}

function createCubeMesh(texLoader, scene, gui, debugObject) {
    const cubeTextureAlpha = texLoader.load('/textures/door/alpha.jpg')
    const cubeTextureAmbientOcclusion = texLoader.load('/textures/door/ambientOcclusion.jpg')
    const cubeTextureColor = texLoader.load('/textures/checkerboard-1024x1024.png', textureSetup)
    const cubeTextureHeight = texLoader.load('/textures/door/height.jpg')
    const cubeTextureMetal = texLoader.load('/textures/door/metalness.jpg')
    const cubeTextureNormal = texLoader.load('/textures/door/normal.jpg')
    const cubeTextureRoughness = texLoader.load('/textures/door/roughness.jpg')

    const cubeGeometry = new THREE.BoxGeometry(1.0, 1.0, 1.0)
    const cubeMaterial = new THREE.MeshBasicMaterial({ map: cubeTextureColor, color: debugObject.color })
    const cubeMesh = new THREE.Mesh(cubeGeometry, cubeMaterial)
    scene.add(cubeMesh)
    cubeMesh.position.set(0, 0, 0)

    const tweaksCube = gui.addFolder("Cube")
    tweaksCube.add(cubeMesh.position, 'y').min(-4).max(4).step(0.1).name("Elevation")
    tweaksCube.add(cubeMesh, 'visible').name("Visible")
    tweaksCube.add(cubeMaterial, 'wireframe').name("Wireframe")
    tweaksCube.addColor(debugObject, 'color').name("Color").onChange((value) => {
        cubeMaterial.color.set(debugObject.color)
    })

    debugObject.spin = () => {
        gsap.to(cubeMesh.rotation, { duration: 1, y: cubeMesh.rotation.y + Math.PI * Math.random() * 2, x: cubeMesh.rotation.x + Math.PI * Math.random() * 2 })
    }
    tweaksCube.add(debugObject, 'spin').name("Spin")

    debugObject.subDivision = 2
    tweaksCube.add(debugObject, 'subDivision').min(1).max(20).step(1).name("Subdivision").onChange((value) => {
        cubeMesh.geometry.dispose()
        cubeMesh.geometry = new THREE.BoxGeometry(1.0, 1.0, 1.0, value, value, value)
    })
}

function directionalLightSetDirection(light, dirX, dirY, dirZ) {
    light.position.set(-dirX, -dirY, -dirZ)
}

const settingsLightsKey = "settingsLights.9"
let settingsLights = {
    ambientEnabled: true,
    ambientColor: 0xff8822,
    ambientIntensity: 1.0,
    
    pointEnabled: true,
    pointColor: 0xffffff,
    pointIntensity: 1.0,
    pointDistance: 1.0,
    pointDecay: 1.0,
    pointPosition: new THREE.Vector3(0.5, 1.0, 0.5),
    
    directionalEnabled: true,
    directionalColor: 0x2266ff,
    directionalIntensity: 10.0,
    directionalDirection: new THREE.Vector3(0.5, -0.5, 0.5),
    
    hemiEnabled: true,
    hemiSkyColor: 0x00b4fd,
    hemiGroundColor: 0xa66227,
    hemiIntensity: 1.0,

    rectAreaEnabled: true,
    rectAreaColor: 0x2288ff,
    rectAreaIntensity: 1.0,
    rectAreaWidth: 3.0,
    rectAreaHeight: 1.0,
    rectAreaPosition: new THREE.Vector3(0.0, 1.0, 1.0),
    rectAreaTarget: new THREE.Vector3(0.0, 0.0, 0.0),

    spotEnabled: true,
    spotColor: 0x2288ff,
    spotIntensity: 1.0,
    spotDistance: 10.0,
    spotDecay: 2.0,
    spotAngle: Math.PI/3,
    spotPenumbra: 0.0,
    spotPosition: new THREE.Vector3(-2.0, 1.0, 0.0),
}

function lightSettingsLoad() {
    const settingsLightsJson = localStorage.getItem(settingsLightsKey)
    if (settingsLightsJson) {
        settingsLights = JSON.parse(settingsLightsJson)
    }
}

function lightSettingsSave() {
    localStorage.setItem(settingsLightsKey, JSON.stringify(settingsLights))
}

function ambientAndDirectionalLight(scene, gui) {
    lightSettingsLoad();
    const l1 = new THREE.AmbientLight(settingsLights.ambientColor, settingsLights.ambientIntensity)
    const l3 = new THREE.DirectionalLight(settingsLights.directionalColor, settingsLights.directionalIntensity)
    directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
    l1.visible = settingsLights.ambientEnabled
    l3.visible = settingsLights.directionalEnabled
    l3.castShadow = true
    l3.shadow.mapSize.width = 2048
    l3.shadow.mapSize.height = 2048
    l3.shadow.camera.near = 1
    l3.shadow.camera.far = 150
    l3.shadow.camera.left = -10
    l3.shadow.camera.bottom = -10
    l3.shadow.camera.right = 8
    l3.shadow.camera.top = 8
    scene.add(l1)
    scene.add(l3)

    const guiLights = gui.addFolder("Lights")

    {
        const guiLightL1 = guiLights.addFolder("Ambient Light")
        guiLightL1.add(settingsLights, 'ambientEnabled').name("Ambient Light").onChange((value) => {
            lightSettingsSave()
            l1.visible = value
        })
        guiLightL1.addColor(settingsLights, 'ambientColor').name("Ambient Color").onChange((value) => {
            lightSettingsSave()
            l1.color.set(value)
        })
        guiLightL1.add(settingsLights, 'ambientIntensity').min(0).max(5).step(0.01).name("Ambient Intensity").onChange((value) => {
            lightSettingsSave()
            l1.intensity = value
        })
    }

    {
        const guiLightL3 = guiLights.addFolder("Directional Light")
        guiLightL3.add(settingsLights, 'directionalEnabled').name("Directional Light").onChange((value) => {
            lightSettingsSave()
            l3.visible = value
        })
        guiLightL3.addColor(settingsLights, 'directionalColor').name("Directional Color").onChange((value) => {
            lightSettingsSave()
            l3.color.set(value)
        })
        guiLightL3.add(settingsLights, 'directionalIntensity').min(0).max(10).step(0.01).name("Directional Intensity").onChange((value) => {
            lightSettingsSave()
            l3.intensity = value
        })
        guiLightL3.add(settingsLights.directionalDirection, 'x').min(-20).max(20).step(0.01).name("Directional Direction X").onChange((value) => {
            lightSettingsSave()
            //directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
            l3.position.set(-settingsLights.directionalDirection.x, -settingsLights.directionalDirection.y, -settingsLights.directionalDirection.z)
        })
        guiLightL3.add(settingsLights.directionalDirection, 'y').min(-20).max(20).step(0.01).name("Directional Direction Y").onChange((value) => {
            lightSettingsSave()
            //directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
            l3.position.set(-settingsLights.directionalDirection.x, -settingsLights.directionalDirection.y, -settingsLights.directionalDirection.z)
        })
        guiLightL3.add(settingsLights.directionalDirection, 'z').min(-20).max(20).step(0.01).name("Directional Direction Z").onChange((value) => {
            lightSettingsSave()
            //directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
            l3.position.set(-settingsLights.directionalDirection.x, -settingsLights.directionalDirection.y, -settingsLights.directionalDirection.z)
        })
    }
    return { ambient: l1, directional: l3 }
}

function testAllLights(scene, gui) {
    lightSettingsLoad();

    const l1 = new THREE.AmbientLight(settingsLights.ambientColor, settingsLights.ambientIntensity)
    const l2 = new THREE.PointLight(settingsLights.pointColor, settingsLights.pointIntensity, settingsLights.pointDistance, settingsLights.pointDecay)
    const l3 = new THREE.DirectionalLight(settingsLights.directionalColor, settingsLights.directionalIntensity)
    const l4 = new THREE.HemisphereLight(settingsLights.hemiSkyColor, settingsLights.hemiGroundColor, settingsLights.hemiIntensity)
    const l5 = new THREE.RectAreaLight(settingsLights.rectAreaColor, settingsLights.rectAreaIntensity, settingsLights.rectAreaWidth, settingsLights.rectAreaHeight)
    const l6 = new THREE.SpotLight(settingsLights.spotColor, settingsLights.spotIntensity, settingsLights.spotDistance, settingsLights.spotAngle, settingsLights.spotPenumbra, settingsLights.spotDecay)
    l2.position.set(settingsLights.pointPosition.x, settingsLights.pointPosition.y, settingsLights.pointPosition.z)
    directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
    l5.position.set(settingsLights.rectAreaPosition.x, settingsLights.rectAreaPosition.y, settingsLights.rectAreaPosition.z)
    l5.lookAt(settingsLights.rectAreaTarget.x, settingsLights.rectAreaTarget.y, settingsLights.rectAreaTarget.z)
    l6.position.set(settingsLights.spotPosition.x, settingsLights.spotPosition.y, settingsLights.spotPosition.z)
    scene.add(l1)
    scene.add(l2)
    scene.add(l3)
    scene.add(l4)
    scene.add(l5)
    scene.add(l6)
    l1.visible = settingsLights.ambientEnabled
    l2.visible = settingsLights.pointEnabled
    l3.visible = settingsLights.directionalEnabled
    l4.visible = settingsLights.hemiEnabled
    l5.visible = settingsLights.rectAreaEnabled
    l6.visible = settingsLights.spotEnabled

    const guiLights = gui.addFolder("Lights")

    {
        const guiLightL1 = guiLights.addFolder("Ambient Light")
        guiLightL1.add(settingsLights, 'ambientEnabled').name("Ambient Light").onChange((value) => {
            lightSettingsSave()
            l1.visible = value
        })
        guiLightL1.addColor(settingsLights, 'ambientColor').name("Ambient Color").onChange((value) => {
            lightSettingsSave()
            l1.color.set(value)
        })
        guiLightL1.add(settingsLights, 'ambientIntensity').min(0).max(5).step(0.01).name("Ambient Intensity").onChange((value) => {
            lightSettingsSave()
            l1.intensity = value
        })
    }
    {
        const guiLightL2 = guiLights.addFolder("Point Light")
        guiLightL2.add(settingsLights, 'pointEnabled').name("Point Light").onChange((value) => {
            lightSettingsSave()
            l2.visible = value
        })
        guiLightL2.addColor(settingsLights, 'pointColor').name("Point Color").onChange((value) => {
            lightSettingsSave()
            l2.color.set(value)
        })
        guiLightL2.add(settingsLights, 'pointIntensity').min(0).max(10).step(0.01).name("Point Intensity").onChange((value) => {
            lightSettingsSave()
            l2.intensity = value
        })
        guiLightL2.add(settingsLights, 'pointDistance').min(0).max(200).step(0.01).name("Point Distance").onChange((value) => {
            lightSettingsSave()
            l2.distance = value
        })
        guiLightL2.add(settingsLights, 'pointDecay').min(0).max(2).step(0.01).name("Point Decay").onChange((value) => {
            lightSettingsSave()
            l2.decay = value
        })
        guiLightL2.add(settingsLights.pointPosition, 'x').min(-10).max(10).step(0.01).name("Point Position X").onChange((value) => {
            lightSettingsSave()
            l2.position.set(settingsLights.pointPosition.x, settingsLights.pointPosition.y, settingsLights.pointPosition.z)
        })
        guiLightL2.add(settingsLights.pointPosition, 'y').min(-10).max(10).step(0.01).name("Point Position Y").onChange((value) => {
            lightSettingsSave()
            l2.position.set(settingsLights.pointPosition.x, settingsLights.pointPosition.y, settingsLights.pointPosition.z)
        })
        guiLightL2.add(settingsLights.pointPosition, 'z').min(-10).max(10).step(0.01).name("Point Position Z").onChange((value) => {
            lightSettingsSave()
            l2.position.set(settingsLights.pointPosition.x, settingsLights.pointPosition.y, settingsLights.pointPosition.z)
        })
    }

    {
        const guiLightL3 = guiLights.addFolder("Directional Light")
        guiLightL3.add(settingsLights, 'directionalEnabled').name("Directional Light").onChange((value) => {
            lightSettingsSave()
            l3.visible = value
        })
        guiLightL3.addColor(settingsLights, 'directionalColor').name("Directional Color").onChange((value) => {
            lightSettingsSave()
            l3.color.set(value)
        })
        guiLightL3.add(settingsLights, 'directionalIntensity').min(0).max(10).step(0.01).name("Directional Intensity").onChange((value) => {
            lightSettingsSave()
            l3.intensity = value
        })
        guiLightL3.add(settingsLights.directionalDirection, 'x').min(-5).max(5).step(0.01).name("Directional Direction X").onChange((value) => {
            lightSettingsSave()
            directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
        })
        guiLightL3.add(settingsLights.directionalDirection, 'y').min(-5).max(5).step(0.01).name("Directional Direction Y").onChange((value) => {
            lightSettingsSave()
            directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
        })
        guiLightL3.add(settingsLights.directionalDirection, 'z').min(-5).max(5).step(0.01).name("Directional Direction Z").onChange((value) => {
            lightSettingsSave()
            directionalLightSetDirection(l3, settingsLights.directionalDirection.x, settingsLights.directionalDirection.y, settingsLights.directionalDirection.z)
        })
    }

    {
        const guiLightL4 = guiLights.addFolder("Hemisphere Light")
        guiLightL4.add(settingsLights, 'hemiEnabled').name("Hemisphere Light").onChange((value) => {
            lightSettingsSave()
            l4.visible = value
        })
        guiLightL4.addColor(settingsLights, 'hemiSkyColor').name("Hemisphere Sky Color").onChange((value) => {
            lightSettingsSave()
            l4.color.set(value)
        })
        guiLightL4.addColor(settingsLights, 'hemiGroundColor').name("Hemisphere Ground Color").onChange((value) => {
            lightSettingsSave()
            l4.groundColor.set(value)
        })
        guiLightL4.add(settingsLights, 'hemiIntensity').min(0).max(20).step(0.01).name("Hemisphere Intensity").onChange((value) => {
            lightSettingsSave()
            l4.intensity = value
        })
    }

    {
        const guiLightL5 = guiLights.addFolder("RectArea Light")
        guiLightL5.add(settingsLights, 'rectAreaEnabled').name("RectArea Light").onChange((value) => {
            lightSettingsSave()
            l5.visible = value
        })
        guiLightL5.addColor(settingsLights, 'rectAreaColor').name("RectArea Color").onChange((value) => {
            lightSettingsSave()
            l5.color.set(value)
        })
        guiLightL5.add(settingsLights, 'rectAreaIntensity').min(0).max(10).step(0.01).name("RectArea Intensity").onChange((value) => {
            lightSettingsSave()
            l5.intensity = value
        })
        guiLightL5.add(settingsLights, 'rectAreaWidth').min(0).max(10).step(0.01).name("RectArea width").onChange((value) => {
            lightSettingsSave()
            l5.width = value
        })
        guiLightL5.add(settingsLights, 'rectAreaHeight').min(0).max(10).step(0.01).name("RectArea Height").onChange((value) => {
            lightSettingsSave()
            l5.height = value
        })
        guiLightL5.add(settingsLights.rectAreaPosition, 'x').min(-5).max(5).step(0.01).name("RectArea Position X").onChange((value) => {
            lightSettingsSave()
            l5.position.set(settingsLights.rectAreaPosition.x, settingsLights.rectAreaPosition.y, settingsLights.rectAreaPosition.z)
        })
        guiLightL5.add(settingsLights.rectAreaPosition, 'y').min(-5).max(5).step(0.01).name("RectArea Position Y").onChange((value) => {
            lightSettingsSave()
            l5.position.set(settingsLights.rectAreaPosition.x, settingsLights.rectAreaPosition.y, settingsLights.rectAreaPosition.z)
        })
        guiLightL5.add(settingsLights.rectAreaPosition, 'z').min(-5).max(5).step(0.01).name("RectArea Position Z").onChange((value) => {
            lightSettingsSave()
            l5.position.set(settingsLights.rectAreaPosition.x, settingsLights.rectAreaPosition.y, settingsLights.rectAreaPosition.z)
        })
        guiLightL5.add(settingsLights.rectAreaTarget, 'x').min(-5).max(5).step(0.01).name("RectArea Target X").onChange((value) => {
            lightSettingsSave()
            l5.lookAt(settingsLights.rectAreaTarget.x, settingsLights.rectAreaTarget.y, settingsLights.rectAreaTarget.z)
        })
        guiLightL5.add(settingsLights.rectAreaTarget, 'y').min(-5).max(5).step(0.01).name("RectArea Target Y").onChange((value) => {
            lightSettingsSave()
            l5.lookAt(settingsLights.rectAreaTarget.x, settingsLights.rectAreaTarget.y, settingsLights.rectAreaTarget.z)
        })
        guiLightL5.add(settingsLights.rectAreaTarget, 'z').min(-5).max(5).step(0.01).name("RectArea Target Z").onChange((value) => {
            lightSettingsSave()
            l5.lookAt(settingsLights.rectAreaTarget.x, settingsLights.rectAreaTarget.y, settingsLights.rectAreaTarget.z)
        })
    }

    {
        const guiLightL6 = guiLights.addFolder("Spot Light")
        guiLightL6.add(settingsLights, 'spotEnabled').name("Spot Light").onChange((value) => {
            lightSettingsSave()
            l6.visible = value
        })
        guiLightL6.addColor(settingsLights, 'spotColor').name("Spot Color").onChange((value) => {
            lightSettingsSave()
            l6.color.set(value)
        })
        guiLightL6.add(settingsLights, 'spotIntensity').min(0).max(10).step(0.01).name("Spot Intensity").onChange((value) => {
            lightSettingsSave()
            l6.intensity = value
        })
        guiLightL6.add(settingsLights, 'spotDistance').min(0).max(100).step(0.01).name("Spot Distance").onChange((value) => {
            lightSettingsSave()
            l6.distance = value
        })
        guiLightL6.add(settingsLights, 'spotDecay').min(0).max(10).step(0.01).name("Spot Decay").onChange((value) => {
            lightSettingsSave()
            l6.decay = value
        })
        guiLightL6.add(settingsLights, 'spotAngle').min(0).max(Math.PI).step(0.01).name("Spot Angle").onChange((value) => {
            lightSettingsSave()
            l6.angle = value
        })
        guiLightL6.add(settingsLights, 'spotPenumbra').min(0).max(1.0).step(0.01).name("Spot Penumbra").onChange((value) => {
            lightSettingsSave()
            l6.penumbra = value
        })
        guiLightL6.add(settingsLights.spotPosition, 'x').min(-5).max(5).step(0.01).name("spot Position X").onChange((value) => {
            lightSettingsSave()
            l6.position.set(settingsLights.spotPosition.x, settingsLights.spotPosition.y, settingsLights.spotPosition.z)
        })
        guiLightL6.add(settingsLights.spotPosition, 'y').min(-5).max(5).step(0.01).name("spot Position Y").onChange((value) => {
            lightSettingsSave()
            l6.position.set(settingsLights.spotPosition.x, settingsLights.spotPosition.y, settingsLights.spotPosition.z)
        })
        guiLightL6.add(settingsLights.spotPosition, 'z').min(-5).max(5).step(0.01).name("spot Position Z").onChange((value) => {
            lightSettingsSave()
            l6.position.set(settingsLights.spotPosition.x, settingsLights.spotPosition.y, settingsLights.spotPosition.z)
        })
    }
}

function environmentMap(scene, rgbeLoader) {
    rgbeLoader.load('./textures/environmentMap/2k.hdr', (envMap) => {
        envMap.mapping = THREE.EquirectangularReflectionMapping
        scene.background = envMap
        scene.environment = envMap
    })
}

function materialTests(texLoader, scene, gui) {
    const doorColorTexture = texLoader.load('./textures/door/color.jpg')
    const doorAlphaTexture = texLoader.load('./textures/door/alpha.jpg')
    const doorAmbientOcclusionTexture = texLoader.load('./textures/door/ambientOcclusion.jpg')
    const doorHeightTexture = texLoader.load('./textures/door/height.jpg')
    const doorNormalTexture = texLoader.load('./textures/door/normal.jpg')
    const doorMetalnessTexture = texLoader.load('./textures/door/metalness.jpg')
    const doorRoughnessTexture = texLoader.load('./textures/door/roughness.jpg')
    const matcapTexture1 = texLoader.load('./textures/matcaps/1.png')
    const matcapTexture2 = texLoader.load('./textures/matcaps/2.png')
    const matcapTexture3 = texLoader.load('./textures/matcaps/3.png')
    const matcapTexture4 = texLoader.load('./textures/matcaps/4.png')
    const matcapTexture5 = texLoader.load('./textures/matcaps/5.png')
    const matcapTexture6 = texLoader.load('./textures/matcaps/6.png')
    const matcapTexture7 = texLoader.load('./textures/matcaps/7.png')
    const matcapTexture8 = texLoader.load('./textures/matcaps/8.png')
    const gradientTexture = texLoader.load('./textures/gradients/5.jpg')
    doorColorTexture.colorSpace = THREE.SRGBColorSpace
    matcapTexture1.colorSpace = THREE.SRGBColorSpace
    matcapTexture2.colorSpace = THREE.SRGBColorSpace
    matcapTexture3.colorSpace = THREE.SRGBColorSpace
    matcapTexture4.colorSpace = THREE.SRGBColorSpace
    matcapTexture5.colorSpace = THREE.SRGBColorSpace
    matcapTexture6.colorSpace = THREE.SRGBColorSpace
    matcapTexture7.colorSpace = THREE.SRGBColorSpace
    matcapTexture8.colorSpace = THREE.SRGBColorSpace
    gradientTexture.generateMipmaps = false
    gradientTexture.minFilter = THREE.NearestFilter
    gradientTexture.magFilter = THREE.NearestFilter

    const settingsMaterialKey = "settingsMaterial.6"
    let settingsMaterial = {
        color: 0xffffff,
        metalness: 1,
        roughness: 1,
        clearcoat: 1,
        clearcoatRoughness: 0,
        sheen: 1,
        sheenRoughness: 0.25,
        sheenColor: 0xffffff,
        iridescence: 1,
        iridescenceIOR: 1,
        transmission: 1,
        transmissionIOR: 1.5,
        transmissionThickness: 0.5,
    }
    const settingsMaterialJson = localStorage.getItem(settingsMaterialKey)
    if (settingsMaterialJson) {
        settingsMaterial = JSON.parse(settingsMaterialJson)
    }

    let sharedMat = new THREE.MeshBasicMaterial({
        map: doorColorTexture,
        transparent: true,
        opacity: 1,
        alphaMap: doorAlphaTexture,
    })

    sharedMat = new THREE.MeshNormalMaterial({
        //flatShading: true,
        //map: doorColorTexture,
        normalMap: doorNormalTexture,
        //normalScale: new THREE.Vector2(0.5, 0.5)
    })

    sharedMat = new THREE.MeshMatcapMaterial({
        matcap: matcapTexture1
    })

    sharedMat = new THREE.MeshDepthMaterial({

    })

    sharedMat = new THREE.MeshLambertMaterial({

    })

    sharedMat = new THREE.MeshPhongMaterial({
        shininess: 10,
        specular: 0xffff88
    })

    sharedMat = new THREE.MeshToonMaterial({
        gradientMap: gradientTexture
    })

    sharedMat = new THREE.MeshStandardMaterial({
        color: settingsMaterial.color,
        metalness: settingsMaterial.metalness,
        metalnessMap: doorMetalnessTexture,
        roughness: settingsMaterial.roughness,
        roughnessMap: doorRoughnessTexture,
        normalMap: doorNormalTexture,
        normalScale: new THREE.Vector2(1, 1),
        map: doorColorTexture,
        aoMap: doorAmbientOcclusionTexture,
        aoMapIntensity: 1,
        displacementMap: doorHeightTexture,
        displacementScale: 0.05,
        transparent: true,
        alphaMap: doorAlphaTexture,
    })

    sharedMat = new THREE.MeshPhysicalMaterial({
        color: settingsMaterial.color,
        metalness: settingsMaterial.metalness,
        metalnessMap: doorMetalnessTexture,
        roughness: settingsMaterial.roughness,
        roughnessMap: doorRoughnessTexture,
        normalMap: doorNormalTexture,
        normalScale: new THREE.Vector2(1, 1),
        map: doorColorTexture,
        aoMap: doorAmbientOcclusionTexture,
        aoMapIntensity: 1,
        displacementMap: doorHeightTexture,
        displacementScale: 0.05,
        transparent: true,
        alphaMap: doorAlphaTexture,
        clearcoat: settingsMaterial.clearcoat,
        clearcoatRoughness: settingsMaterial.clearcoatRoughness,
        sheen: settingsMaterial.sheen,
        sheenRoughness: settingsMaterial.sheenRoughness,
        sheenColor: settingsMaterial.sheenColor,
        iridescence: settingsMaterial.iridescence,
        iridescenceIOR: settingsMaterial.iridescenceIOR,
        //iridescenceRange: [100, 800],
        transmission: settingsMaterial.transmission,
        ior: settingsMaterial.transmissionIOR,
        thickness: settingsMaterial.transmissionThickness,
    })




    const guiSM = gui.addFolder("Standard / Physical Material")
    guiSM.addColor(settingsMaterial, 'color').name("Color").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.color.set(value)
    })
    guiSM.add(settingsMaterial, 'metalness').min(0).max(1).step(0.05).name("Metalness").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.metalness = value
    })
    guiSM.add(settingsMaterial, 'roughness').min(0).max(1).step(0.05).name("Roughness").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.roughness = value
    })
    guiSM.add(settingsMaterial, 'clearcoat').min(0).max(1).step(0.05).name("Clearcoat").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.clearcoat = value
    })
    guiSM.add(settingsMaterial, 'clearcoatRoughness').min(0).max(1).step(0.05).name("Clearcoat Roughness").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.clearcoatRoughness = value
    })
    guiSM.add(settingsMaterial, 'sheen').min(0).max(1).step(0.05).name("Sheen").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.sheen = value
    })
    guiSM.add(settingsMaterial, 'sheenRoughness').min(0).max(1).step(0.05).name("Sheen Roughness").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.sheenRoughness = value
    })
    guiSM.addColor(settingsMaterial, 'sheenColor').name("Sheen Color").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.sheenColor.set(value)
    })
    guiSM.add(settingsMaterial, 'iridescence').min(0).max(1).step(0.05).name("Iridescence").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.iridescence = value
    })
    guiSM.add(settingsMaterial, 'iridescenceIOR').min(0).max(5).step(0.05).name("Iridescence IOR").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.iridescenceIOR = value
    })
    guiSM.add(settingsMaterial, 'transmission').min(0).max(1).step(0.05).name("Transmission").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.transmission = value
    })
    guiSM.add(settingsMaterial, 'transmissionIOR').min(0).max(5).step(0.05).name("Transmission IOR").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.ior = value
    })
    guiSM.add(settingsMaterial, 'transmissionThickness').min(0).max(1).step(0.05).name("Transmission Thickness").onChange((value) => {
        localStorage.setItem(settingsMaterialKey, JSON.stringify(settingsMaterial))
        sharedMat.thickness = value
    })

    const subDiv = 128
    const sphereGeo = new THREE.SphereGeometry(0.5, subDiv, subDiv)
    const sphere = new THREE.Mesh(sphereGeo, sharedMat)
    sphere.position.set(-1.5, 0, 0)

    const donutGeo = new THREE.TorusGeometry(0.3, 0.2, subDiv, subDiv * 2)
    const donut = new THREE.Mesh(donutGeo, sharedMat)
    donut.position.set(1.5, 0, 0)

    const cubeGeo = new THREE.BoxGeometry(1, 1, 1, subDiv, subDiv, subDiv)
    const cube = new THREE.Mesh(cubeGeo, sharedMat)
    cube.position.set(0, 0, 0)

    scene.add(sphere)
    scene.add(donut)
    scene.add(cube)
}

function createShadowsTest(scene, gui, renderer) {
    ambientAndDirectionalLight(scene, gui)
    renderer.shadowMap.enabled = true
    //renderer.shadowMap.type = THREE.PCFShadowMap
    renderer.shadowMap.type = THREE.PCFSoftShadowMap
    
    
    const sharedMat = new THREE.MeshStandardMaterial()
    
    const floorGeo = new THREE.PlaneGeometry(10, 10)
    const floor = new THREE.Mesh(floorGeo, sharedMat)
    floor.castShadow = true
    floor.receiveShadow = true
    floor.rotation.set(-Math.PI / 2, 0, 0)
    
    const subDiv = 64
    const sphereGeo = new THREE.SphereGeometry(0.5, subDiv, subDiv)
    const sphere = new THREE.Mesh(sphereGeo, sharedMat)
    sphere.castShadow = true
    sphere.receiveShadow = true
    sphere.position.set(0, 0.5, 0)
    
    const donutGeo = new THREE.TorusGeometry(0.3, 0.2, subDiv, subDiv * 2)
    const donut = new THREE.Mesh(donutGeo, sharedMat)
    donut.castShadow = true
    donut.receiveShadow = true
    donut.position.set(1.5, 0.5, 0)
    
    const cubeGeo = new THREE.BoxGeometry(1, 1, 1, subDiv, subDiv, subDiv)
    const cube = new THREE.Mesh(cubeGeo, sharedMat)
    cube.castShadow = true
    cube.receiveShadow = true
    cube.position.set(-1.5, 0.5, 0)
    
    scene.add(floor)
    scene.add(sphere)
    scene.add(donut)
    scene.add(cube)
}

function updateObjects(elapsedTime, deltaTime) {
    sphere.rotation.y = 0.1 * elapsedTime
    cube.rotation.y = 0.1 * elapsedTime
    donut.rotation.y = 0.1 * elapsedTime

    sphere.rotation.x = - 0.15 * elapsedTime
    cube.rotation.x = - 0.15 * elapsedTime
    donut.rotation.x = - 0.15 * elapsedTime
}


//
// One way to load textures using native JavaScript
//
// const cubeImage = new Image()
// const cubeTexture = new THREE.Texture(cubeImage)
// cubeTexture.colorSpace = THREE.SRGBColorSpace
// cubeImage.onload = () => {
//     cubeTexture.needsUpdate = true
// }
// cubeImage.src = '/textures/door/color.jpg'


function fontExperiment(fontLoader, texLoader, scene) {
    const matcapTexture1 = texLoader.load('./textures/matcaps/1.png')
    const matcapTexture2 = texLoader.load('./textures/matcaps/2.png')
    const matcapTexture3 = texLoader.load('./textures/matcaps/3.png')
    const matcapTexture4 = texLoader.load('./textures/matcaps/4.png')
    const matcapTexture5 = texLoader.load('./textures/matcaps/5.png')
    const matcapTexture6 = texLoader.load('./textures/matcaps/6.png')
    const matcapTexture7 = texLoader.load('./textures/matcaps/7.png')
    const matcapTexture8 = texLoader.load('./textures/matcaps/8.png')
    matcapTexture1.colorSpace = THREE.SRGBColorSpace
    matcapTexture2.colorSpace = THREE.SRGBColorSpace
    matcapTexture3.colorSpace = THREE.SRGBColorSpace
    matcapTexture4.colorSpace = THREE.SRGBColorSpace
    matcapTexture5.colorSpace = THREE.SRGBColorSpace
    matcapTexture6.colorSpace = THREE.SRGBColorSpace
    matcapTexture7.colorSpace = THREE.SRGBColorSpace
    matcapTexture8.colorSpace = THREE.SRGBColorSpace

    fontLoader.load('/fonts/Workbench_Regular.json', createText);
    function createText(font) {
        const textGeo = new TextGeometry('RoarWare\nComputer Technology Company', {
            font: font,
            size: 0.5,
            depth: 0.2,
            curveSegments: 5,
            bevelEnabled: true,
            bevelThickness: 0.03,
            bevelSize: 0.02,
            bevelOffset: 0,
            bevelSegments: 4
        })
        textGeo.computeBoundingBox()
        textGeo.center()
        const textMat = new THREE.MeshMatcapMaterial({ matcap: matcapTexture7 })
        const text = new THREE.Mesh(textGeo, textMat)
        scene.add(text)

        console.time('donuts')
        const matArray = [
            new THREE.MeshMatcapMaterial({ matcap: matcapTexture2 }),
            new THREE.MeshMatcapMaterial({ matcap: matcapTexture6 }),
            new THREE.MeshMatcapMaterial({ matcap: matcapTexture8 })
        ]
        const geoArray = [
            new THREE.TorusGeometry(0.3, 0.2, 20, 45),
            new THREE.BoxGeometry(1, 1, 1,),
            new THREE.SphereGeometry(0.5, 32, 32),
        ]
        const radius = 10
        for (let i = 0; i < 1000; ++i) {
            const g = geoArray[Math.floor(Math.random() * geoArray.length)]
            const m = matArray[Math.floor(Math.random() * matArray.length)]
            const donut = new THREE.Mesh(g, m)
            const point = randomPointOnSphere(radius * 0.75, radius)
            donut.position.set(point.x, point.y, point.z)
            donut.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, Math.random() * Math.PI)
            scene.add(donut)
        }
        console.timeEnd('donuts')
    }
}

export {
    randomPointOnSphere,
    createTestGroup,
    createRandomMesh,
    createCubeMesh,
    ambientAndDirectionalLight,
    testAllLights,
    environmentMap,
    materialTests,
    createShadowsTest,
    fontExperiment
};
