// User Input
//  Keyboard
//  Mouse
//  Touch
//  Gamepads

import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js'
import { FontLoader } from 'three/addons/loaders/FontLoader.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js'
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import GUI from 'lil-gui'
import gsap from 'gsap'
import CANNON from 'cannon'

import {
    randomPointOnSphere,
    createTestGroup,
    createRandomMesh,
    createCubeMesh,
    ambientAndDirectionalLight,
    testAllLights,
    environmentMap,
    materialTests,
    createShadowsTest,
    fontExperiment
} from './utils.js';

function getRandomColorByRGB() {
    const red = Math.random();   // Random red component from 0.0 to 1.0
    const green = Math.random(); // Random green component from 0.0 to 1.0
    const blue = Math.random();  // Random blue component from 0.0 to 1.0
    const color = new THREE.Color(red, green, blue);
    return color;
}

function randomRange(min, max) {
    return Math.random() * (max - min) + min;
}

const urlParams = new URLSearchParams(location.search);
for (const [key, value] of urlParams) {
    console.log(`${key}:${value}`);
}

//
// Base class for Lessons
//
class TutorialLesson {
    constructor(name, description) {
        this.title = name
        this.description = description
    }

    update(time, deltaTime) {
    }

    reset() {
    }

    onClickEvent(event) {

    }
}

//
// Global Object to hold things like the GUI, Scene, etc.
//
const global = {
    // lil-gui
    gui: new GUI({
        title: "Tweaks",
        width: window.innerWidth * 0.25,
        closeFolders: false
    }),
    renderer: null,
    camera: null,
    orbitCtrl: null,
    clock: new THREE.Clock(),
    canvas: document.getElementById('webgl'),
    scene: new THREE.Scene(),
    raycaster: new THREE.Raycaster(),

    mouseCursor: {
        x: -1,
        y: 1
    },
    
    mouseNorm: new THREE.Vector2(-1,1),
    
    renderSize: {
        width: window.innerWidth,
        height: window.innerHeight,
        aspectRatio: window.innerWidth / window.innerHeight
    },
    
    updateViewportSize() {
        global.renderSize.width = window.innerWidth
        global.renderSize.height = window.innerHeight
        global.renderSize.aspectRatio = global.renderSize.width / global.renderSize.height
        global.renderer.setSize(global.renderSize.width, global.renderSize.height)
        global.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        global.camera.aspect = global.renderSize.aspectRatio
        global.camera.updateProjectionMatrix()
    },

    reset() {
        for (const lesson of lessons) {
            lesson.reset()
        }    
    }
}

global.gui.add({ reset: () => { global.reset() } }, 'reset')

class KeyEvents {
    constructor() {
        this.keydownPrev = {}
        this.keydown = []
        this.keyrepeat = []
        this.keyup = []
        this.down = {}
        this.pressed = {}
        this.released = {}
    }

    onEventDown(event) {
        if( this.down[event.key] )
            this.keyrepeat.push(event)
        else
            this.keydown.push(event)
    }

    onEventUp(event) {
        this.keyup.push(event)
    }

    update() {
        this.pressed = {}
        this.released = {}
        this.keyup.forEach((event) => {
            this.down[event.key] = false
            this.released[event.key] = true
        })
        this.keydown.forEach((event) => {
            this.down[event.key] = true
            this.pressed[event.key] = this.down[event.key] && !this.keydownPrev[event.key]
        })

        // Store the previous values for next update
        this.keydownPrev = {}
        this.keydown.forEach((event) => {
            this.keydownPrev[event.key] = true
        })

        this.keydown = []
        this.keyrepeat = []
        this.keyup = []
    }

    isDown(key) {
        return this.down[key]
    }

    isPressed(key) {
        return this.pressed[key]
    }

    isReleased(key) {
        return this.released[key]
    }
}

const keyEvents = new KeyEvents()

//global.gui.close()
//global.gui.hide()

window.addEventListener('keydown', (event) => {
    keyEvents.onEventDown(event)
})

window.addEventListener('keyup', (event) => {
    keyEvents.onEventUp(event)    
})

//gsap.to(group.position, { duration: 1, delay: 1, x: 2 })
//gsap.to(group.position, { duration: 1, delay: 2, x: -2 })


//
// Rendering
//
global.renderer = new THREE.WebGLRenderer({
    canvas: global.canvas,
    alpha: true
})

//
// Camera Setup
//
global.camera = new THREE.PerspectiveCamera(75, global.renderSize.aspectRatio, 0.1, 1000)
//const camera = new THREE.OrthographicCamera(-10 * renderSize.aspectRatio, 10 * aspectRatio, 10, -10, 0.1, 1000);
global.camera.position.set(0, 0, 2)
global.camera.up = new THREE.Vector3(0, 1, 0)
global.camera.lookAt(new THREE.Vector3(0, 0, 0))
global.scene.add(global.camera)

//
// Camera Orbit Controls
//
global.orbitCtrl = null //new OrbitControls(camera, canvas)
if (global.orbitCtrl !== null)
    global.orbitCtrl.enableDamping = true

//
// Unused Camera Controls - Using OrbitControls instead
//
const cameraControls = () => {
    const x = Math.sin(mouseCursor.x * Math.PI) * 5
    const z = Math.cos(mouseCursor.x * Math.PI) * 5
    camera.position.set(x, mouseCursor.y * 4, z);
    camera.lookAt(cubeMesh.position);
}

//
// Event Listeners
//
window.addEventListener('resize', (event) => {
    global.updateViewportSize()
})

window.addEventListener('mousemove', (event) => {
    // Top-Left is (-1,-1)
    // Bottom-Right is (+1,+1)
    var rect = global.canvas.getBoundingClientRect()
    global.mouseCursor.x = (((event.clientX - rect.left) / (rect.right - rect.left)) - 0.5) * 2
    global.mouseCursor.y = (((event.clientY - rect.top) / (rect.bottom - rect.top)) - 0.5) * -2
    global.mouseNorm.x = (event.clientX / global.renderSize.width) * 2 - 1
    global.mouseNorm.y = (event.clientY / global.renderSize.height) * -2 + 1
    //console.log("MouseCursor: " + global.mouseCursor.x + ", " + global.mouseCursor.y)
    //console.log("MouseNorm: " + global.mouseNorm.x + ", " + global.mouseNorm.y)
})

//
// https://developer.mozilla.org/en-US/docs/Web/API/Touch_events
//

window.addEventListener('click', (event) => {
    for( const lesson of lessons ) {
        lesson.onClickEvent(event)
    }
})

window.addEventListener('dblclick', (event) => {
    // Webkit for Safari support
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement
    if (fullscreenElement) {
        if (document.exitFullscreen)
            document.exitFullscreen()
        else if (document.webkitExitFullscreen)
            document.webkitExitFullscreen()
    }
    else {
        if (global.canvas.requestFullscreen)
        global.canvas.requestFullscreen()
        else if (global.canvas.webkitRequestFullscreen)
        global.canvas.webkitRequestFullscreen()
    }
})

//scene.add(createRandomMesh())

const loadingManager = new THREE.LoadingManager()
// loadingManager.onStart = () => { console.log("Loading Started") }
// loadingManager.onLoad = () => { console.log("Loading Completed") }
// loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => { console.log("Loading Progress:", url, itemsLoaded, itemsTotal) }
const texLoader = new THREE.TextureLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader()
const rgbeLoader = new RGBELoader(loadingManager)
const fontLoader = new FontLoader(loadingManager)
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('/draco/')
const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

const params = {
    foo: 0
}
global.gui.add(params, 'foo', -5, 5, 0.1).onChange((value) => { console.log("Foo Change: " + value) }).onFinishChange((value) => { console.log("Foo Finish: " + value) })


//
// Stuff
//
//fontExperiment(fontLoader, texLoader, scene)
// environmentMap(scene, rgbeLoader, )
// materialTests(texLoader, scene, global.gui)

//
// Lights
//
//testAllLights(scene, global.gui)

//
// Shadows Test
//
//createShadowsTest(scene, global.gui, renderer)


//
// Scrolling Lesson
//
class ScrollingLesson extends TutorialLesson {
    constructor() {
        super("Scrolling", "Scrolling is cool")

        ambientAndDirectionalLight(global.scene, global.gui)

        global.camera.position.set(0, 0, 6)
        global.camera.fov = 35
        this.objectDistance = 4

        const particleCount = 1000
        const positions = new Float32Array(particleCount * 3)
        const particleRangeX = 10
        const particleRangeY = 4 * 4
        const particleRangeZ = 10
        const particleOffsetX = 0
        const particleOffsetY = -4
        const particleOffsetZ = -4
        for (let i = 0; i < particleCount; ++i) {
            positions[i * 3 + 0] = Math.random() * particleRangeX - 0.5 * particleRangeX + particleOffsetX
            positions[i * 3 + 1] = Math.random() * particleRangeY - 0.5 * particleRangeY + particleOffsetY
            positions[i * 3 + 2] = Math.random() * particleRangeZ - 0.5 * particleRangeZ + particleOffsetZ
        }
        const particleGeometry = new THREE.BufferGeometry()
        particleGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        const particleMat = new THREE.PointsMaterial({
            color: 0xffeedd,
            sizeAttenuation: true,
            size: 0.03
        })
        const particles = new THREE.Points(particleGeometry, particleMat)

        this.objectGroup = new THREE.Group()
        global.scene.add(this.objectGroup)
        this.objectGroup.add(particles)

        const gradientTexture3 = texLoader.load('./textures/gradients/3.jpg')
        const gradientTexture5 = texLoader.load('./textures/gradients/5.jpg')
        gradientTexture3.minFilter = THREE.NearestFilter
        gradientTexture3.magFilter = THREE.NearestFilter
        gradientTexture5.minFilter = THREE.NearestFilter
        gradientTexture5.magFilter = THREE.NearestFilter
        const mat = new THREE.MeshToonMaterial({
            color: 0xfb8500,
            map: gradientTexture3
        })

        const t1 = new THREE.Mesh(
            new THREE.TorusGeometry(1, 0.4, 16, 60),
            mat
        )
        const t2 = new THREE.Mesh(
            new THREE.ConeGeometry(1, 2, 32),
            mat
        )
        const t3 = new THREE.Mesh(
            new THREE.TorusKnotGeometry(0.8, 0.35, 100, 16),
            mat
        )
        this.sectionMeshes = [t1, t2, t3]
        this.objectGroup.add(t1, t2, t3)

        t1.scale.set(0.5, 0.5, 0.5)
        t2.scale.set(0.5, 0.5, 0.5)
        t3.scale.set(0.5, 0.5, 0.5)
        t1.position.y = -this.objectDistance * 0
        t2.position.y = -this.objectDistance * 1
        t3.position.y = -this.objectDistance * 2
        t1.position.x = 2;
        t2.position.x = -2;
        t3.position.x = 2;

        let scrollY = window.scrollY
        let sectionID = 0
        let enableSectionTransition = false

        window.addEventListener('scroll', (event) => {
            scrollY = window.scrollY
            const newSection = Math.round(scrollY / global.renderSize.height)
            const offset = (Math.round(scrollY * 10 / global.renderSize.height) / 10) - sectionID
            //console.log("Offset: " + offset)
            if (newSection !== sectionID) {
                //console.log("Transition from Section " + sectionID + " to section " + newSection + " at offset " + offset)
                sectionID = newSection
                enableSectionTransition = true
            }

            if (Math.abs(offset) >= 0.3)
                enableSectionTransition = true

            if (enableSectionTransition && Math.abs(offset) <= 0.2) {
                enableSectionTransition = false
                gsap.to(
                    this.sectionMeshes[sectionID].rotation,
                    {
                        duration: 1.5,
                        ease: "power2.inOut",
                        y: "+=6",
                    }
                )

                gsap.to(
                    this.sectionMeshes[sectionID].scale,
                    {
                        duration: 0.5,
                        ease: "elastic.inOut",
                        x: 0.5,
                        y: 0.5,
                        z: 0.5
                    }
                )
            }
        })
    }

    update(time, deltaTime) {
        const parallaxX = -global.mouseCursor.x * 0.2
        const parallaxY = -global.mouseCursor.y * 0.2
        const diffX = parallaxX - this.objectGroup.position.x
        const diffY = parallaxY - this.objectGroup.position.y
        this.objectGroup.position.x += diffX * deltaTime * 5
        this.objectGroup.position.y += diffY * deltaTime * 5
        //this.objectGroup.position.set(parallaxX, parallaxY, 0)
        global.camera.position.y = (-(scrollY / global.renderSize.height) * this.objectDistance)
    
        for (const mesh of this.sectionMeshes) {
            mesh.rotation.x += 0.1 * deltaTime
            mesh.rotation.z += 0.12 * deltaTime
        }
    }
}

//
// Particle Lesson
//
class ParticleLesson extends TutorialLesson {
    constructor() {
        super("Particles", "Particles are cool")
        const particleGeom = new THREE.SphereGeometry(1, 32, 32)
        const particleMaterial = new THREE.PointsMaterial({
            size: 0.02,
            sizeAttenuation: true
        })
        this.particleSystem = new THREE.Points(particleGeom, particleMaterial)
        global.scene.add(this.particleSystem)
    }

    update(time, deltaTime) {
        this.particleSystem.rotation.y += 0.1 * deltaTime
        this.particleSystem.rotation.z += 0.15 * deltaTime
        const scale = Math.sin(time * 0.5) * 0.5 + 1
        this.particleSystem.scale.set(scale, scale, scale)
    }
}

class ParticleLesson2 extends TutorialLesson {
    constructor() {
        super("Particles 2", "Particles are cool")

        const textures = [
            texLoader.load('/textures/particles/1.png'),
            texLoader.load('/textures/particles/2.png'),
            texLoader.load('/textures/particles/3.png'),
            texLoader.load('/textures/particles/4.png'),
            texLoader.load('/textures/particles/5.png'),
            texLoader.load('/textures/particles/6.png'),
            texLoader.load('/textures/particles/7.png'),
            texLoader.load('/textures/particles/8.png'),
            texLoader.load('/textures/particles/9.png'),
            texLoader.load('/textures/particles/10.png'),
            texLoader.load('/textures/particles/11.png'),
            texLoader.load('/textures/particles/12.png'),
            texLoader.load('/textures/particles/13.png'),
        ]
        this.particleSystems = []
        for (let t = 0; t < textures.length; ++t) {
            const count = 4000
            const particleGeom = new THREE.BufferGeometry()

            const positions = new Float32Array(count * 3)
            for (let i = 0; i < count; ++i) {
                const [x, y, z] = randomPointOnSphere(0.75, 1.0)
                positions[i * 3 + 0] = x
                positions[i * 3 + 1] = y
                positions[i * 3 + 2] = z
            }
            particleGeom.setAttribute('position', new THREE.BufferAttribute(positions, 3))

            const colors = new Float32Array(count * 3)
            for (let i = 0; i < count; ++i) {
                colors[i * 3 + 0] = Math.random()
                colors[i * 3 + 1] = Math.random()
                colors[i * 3 + 2] = Math.random()
            }
            particleGeom.setAttribute('color', new THREE.BufferAttribute(colors, 3))

            const particleMaterial = new THREE.PointsMaterial({
                size: 0.2,
                sizeAttenuation: true,
                color: 0xffffff,
                alphaMap: textures[t],
                transparent: true,
                depthWrite: false,
                //depthTest: false
                blending: THREE.AdditiveBlending,
                vertexColors: true
            })
            const particleSystem = new THREE.Points(particleGeom, particleMaterial)
            this.particleSystems.push(particleSystem)
            global.scene.add(particleSystem)
        }
    }

    update(time, deltaTime) {
        for (let i = 0; i < this.particleSystems.length; ++i) {
            const particleSystem = this.particleSystems[i]
            particleSystem.rotation.y += 0.1 * deltaTime * ((i + 1) / 10)
            particleSystem.rotation.z += 0.15 * deltaTime * ((i + 1) / 10)
            const scale = Math.sin(time * 1 * ((i + 1) / 10)) * 0.75 + 3
            particleSystem.scale.set(scale, scale, scale)
        }
    }
}

function forEachMesh(object, callback) {
    if (object instanceof THREE.Mesh) {
        callback(object)
    }
    if (object.children.length > 0) {
        for (const child of object.children) {
            forEachMesh(child, callback)
        }
    }
}

//
// Loading GLTF Models
//
class LoadingModelsLesson extends TutorialLesson {
    constructor() {
        super("Loading Models", "Loading models is cool")

        this.mixer = null

        global.camera.position.set(0, 1, 5)
        global.camera.lookAt(new THREE.Vector3(0, 0, 0))

        global.orbitCtrl = new OrbitControls(global.camera, global.canvas)
        global.orbitCtrl.enableDamping = true

        const lights = ambientAndDirectionalLight(global.scene, global.gui)
        global.renderer.shadowMap.enabled = true
        global.renderer.shadowMap.type = THREE.PCFSoftShadowMap

        const helper = new THREE.CameraHelper( lights.directional.shadow.camera );
        global.scene.add( helper );

        const floorTex = texLoader.load('/textures/prototype/texture_08.png')
        floorTex.filter = THREE.NearestFilter
        floorTex.wrapS = THREE.RepeatWrapping
        floorTex.wrapT = THREE.RepeatWrapping
        floorTex.repeat.set(1*5, 1*5)
        const floorMat = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            metalness: 0.2,
            roughness: 0.8,
            map: floorTex,
        })
        const floor = new THREE.Mesh(new THREE.PlaneGeometry(10, 10, 16, 16), floorMat)
        floor.receiveShadow = true
        floor.rotation.set(-Math.PI / 2, 0, 0)
        global.scene.add(floor)

        const cubeGeometry = new THREE.BoxGeometry(1.0, 1.0, 1.0)
        const cubeMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, map: floorTex })
        const cubeMesh = new THREE.Mesh(cubeGeometry, cubeMaterial)
        global.scene.add(cubeMesh)
        cubeMesh.position.set(-1.5, 0.5, -1.5)
        cubeMesh.receiveShadow = true
        cubeMesh.castShadow = true

        this.ready = false
        gltfLoader.load(
            //'./models/Duck/glTF/Duck.gltf',
            //'./models/Duck/glTF-Binary/Duck.glb',
            //'./models/Duck/glTF-Draco/Duck.gltf',
            //'./models/Duck/glTF-Embedded/Duck.gltf',
            //'./models/FlightHelmet/glTF/FlightHelmet.gltf',
            './models/Fox/glTF/Fox.gltf',
            (gltf) => {
                this.model = gltf.scene//.children[0].children[0]
                this.model.scale.set(0.025, 0.025, 0.025)
                global.scene.add(this.model)
                this.ready = true
                if( gltf.animations.length > 0 ) {
                    console.log(gltf.animations)
                    this.mixer = new THREE.AnimationMixer(gltf.scene)
                    const action = this.mixer.clipAction(gltf.animations[0])
                    action.play()
                }
                this.model.rotation.set(0, -Math.PI * 0.25, 0)
                forEachMesh(this.model, (mesh) => {
                    mesh.castShadow = true
                })
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded')
            },
            (error) => {
                console.log('An error happened', error)
            }
        )
    }

    update(time, deltaTime) {
        if(this.ready) {
            //this.model.rotation.y += 0.5 * deltaTime
            //this.model.rotation.z += 0.1 * deltaTime
            if(this.mixer!==null) {
                this.mixer.update(deltaTime)            
            }
        }
    }
}


//
// Physics Test
//
class PhysicsLesson extends TutorialLesson {
    constructor() {
        super("Physics", "Physics are cool")

        this.objectList = []

        global.camera.position.set(0, 1, 5)
        global.camera.lookAt(new THREE.Vector3(0, 0, 0))

        global.orbitCtrl = new OrbitControls(global.camera, global.canvas)
        global.orbitCtrl.enableDamping = true

        const lights = ambientAndDirectionalLight(global.scene, global.gui)
        global.renderer.shadowMap.enabled = true
        global.renderer.shadowMap.type = THREE.PCFSoftShadowMap

        this.environmentMapTexture = cubeTextureLoader.load([
            '/textures/environmentMaps/2/px.png',
            '/textures/environmentMaps/2/nx.png',
            '/textures/environmentMaps/2/py.png',
            '/textures/environmentMaps/2/ny.png',
            '/textures/environmentMaps/2/pz.png',
            '/textures/environmentMaps/2/nz.png'
        ])

        this.hitSound = new Audio('/sounds/hit.mp3')

        this.world = new CANNON.World()
        this.world.broadphase = new CANNON.SAPBroadphase(this.world)
        this.world.allowSleep = true
        this.world.gravity.set(0, -9.82, 0)
        this.matConcrete = new CANNON.Material('concrete')
        this.matPlastic = new CANNON.Material('plastic')
        this.concretePlasticContact = new CANNON.ContactMaterial(
            this.matConcrete,
            this.matPlastic,
            { friction: 0.5, restitution: 0.1 })
        this.plasticPlasticContact = new CANNON.ContactMaterial(
            this.matPlastic,
            this.matPlastic,
            { friction: 0.9, restitution: 0.8 })
        this.world.addContactMaterial(this.concretePlasticContact)
        this.world.addContactMaterial(this.plasticPlasticContact)


        const floorShape = new CANNON.Plane()
        const floorBody = new CANNON.Body()
        floorBody.mass = 0
        floorBody.material = this.matConcrete
        floorBody.addShape(floorShape)
        floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(-1, 0, 0), Math.PI * 0.5)
        this.world.addBody(floorBody)

        const floorTex = texLoader.load('/textures/prototype/texture_08.png')
        floorTex.filter = THREE.NearestFilter
        floorTex.wrapS = THREE.RepeatWrapping
        floorTex.wrapT = THREE.RepeatWrapping

        const floor = new THREE.Mesh(
            new THREE.PlaneGeometry(30, 30),
            new THREE.MeshStandardMaterial({
                color: 0xaaaaaa,
                metalness: 0.3,
                roughness: 0.6,
                map: floorTex,
                envMap: this.environmentMapTexture,
                envMapIntensity: 0.5
            })
        )
        floor.geometry.computeBoundingBox()
        const size = new THREE.Vector3()
        floor.geometry.boundingBox.getSize(size)
        floorTex.repeat.set(size.x*0.5, size.y*0.5)
        floor.receiveShadow = true
        floor.rotation.x = - Math.PI * 0.5
        global.scene.add(floor)


        for( let i=0; i<1; ++i) {
            this.createSphere(0.5)
            //this.createBox(randomRange(0.25,1))
        }


        gltfLoader.load(
            //'/models/jeep.glb',
            //'/models/modern_cartoon_sports_car.glb',
            //'/models/steamboat_willie.glb',
            //'/models/willy.glb',
            //'/models/epic_mickey_2__mickey_mouse.glb',
            //'/models/fighter_plane.glb',
            //'/models/firetruck.glb',
            //'/models/cars/1983_porsche_911_carrera/scene.gltf',
            //'/models/cars/bmw_e24_635csi/scene.gltf',
            //'/models/cars/car__4__3d_model/scene.gltf',
            //'/models/cars/chevrolet_chevelle_ss/scene.gltf',
            '/models/cars/ford_escort_rs_1600/scene.gltf',
            //'/models/cars/lada_vaz-2103_zhiguli/scene.gltf',
            //'/models/cars/mustang_shelby_gt500_eleanor_1967/scene.gltf',
            (gltf) => {
                this.model = gltf.scene//.children[0].children[0]
                //this.model.scale.set(2*0.25, 2*0.25, 2*0.25)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(1.5, 1.5, 1.5)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(0.156, 0.156, 0.156)
                this.model.scale.set(0.0254*2, 0.0254*2, 0.0254*2)
                //this.model.scale.set(1, 1, 1)
                //this.model.scale.set(0.01, 0.01, 0.01)
                global.scene.add(this.model)
                this.ready = true
                if( gltf.animations.length > 0 ) {
                    console.log(gltf.animations)
                    this.mixer = new THREE.AnimationMixer(gltf.scene)
                    const action = this.mixer.clipAction(gltf.animations[0])
                    action.play()
                }
                this.model.rotation.set(0, -Math.PI * 0.25, 0)
                forEachMesh(this.model, (mesh) => {
                    mesh.castShadow = true
                })
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total * 100) + '% loaded')
            },
            (error) => {
                console.log('An error happened', error)
            }
        )

    }

    playHitSfx(collision) {
        const velocity = collision.contact.getImpactVelocityAlongNormal()
        const strength = Math.abs(velocity)
        if( strength < 1)
            return
        if( this.hitSound.paused === false)
            return
        this.hitSound.currentTime = 0
        this.hitSound.volume = strength>=2 ? randomRange(0.5, 1.0) : randomRange(0.125, 0.5)
        this.hitSound.play()
    }

    createBox(size) {
        const position = new CANNON.Vec3(randomRange(-5,5), randomRange(3,5), randomRange(-5,5))
        const box = new THREE.Mesh(
            new THREE.BoxGeometry(size, size, size),
            new THREE.MeshStandardMaterial({
                color: getRandomColorByRGB(),
                metalness: 0.3,
                roughness: 0.4,
                envMap: this.environmentMapTexture,
                envMapIntensity: 0.5
            })
        )
        box.castShadow = true
        box.position.x = position.x
        box.position.y = position.y
        box.position.z = position.z
        global.scene.add(box)

        const boxShape = new CANNON.Box(new CANNON.Vec3(size * 0.5, size * 0.5, size * 0.5))
        const boxBody = new CANNON.Body({
            mass: 1,
            material: this.matPlastic,
            position: position,
            shape: boxShape
        })
        this.world.addBody(boxBody)
        boxBody.addEventListener('collide', (event) => {
            this.playHitSfx(event)
        })

        this.objectList.push({ mesh: box, body: boxBody })
    }

    createSphere(radius) {
        const position = new CANNON.Vec3(randomRange(-5,5), randomRange(3,5), randomRange(-5,5))

        const sphere = new THREE.Mesh(
            new THREE.SphereGeometry(radius, 32, 32),
            new THREE.MeshStandardMaterial({
                color: getRandomColorByRGB(),
                metalness: 0.3,
                roughness: 0.4,
                envMap: this.environmentMapTexture,
                envMapIntensity: 0.5
            })
        )
        sphere.castShadow = true
        sphere.position.x = position.x
        sphere.position.y = position.y
        sphere.position.z = position.z
        global.scene.add(sphere)

        const sphereShape = new CANNON.Sphere(radius)
        const sphereBody = new CANNON.Body({
            mass: 1,
            material: this.matPlastic,
            position: position,
            shape: sphereShape
        })
        this.world.addBody(sphereBody)
        sphereBody.addEventListener('collide', (event) => {
            this.playHitSfx(event)
        })

        this.objectList.push({ mesh: sphere, body: sphereBody })
    }

    timer = 0
    
    update(time, deltaTime) {

        // global.raycaster.setFromCamera(global.mouseNorm, global.camera)
        // const intersects = global.raycaster.intersectObjects(global.scene.children)
        // if( intersects.length > 0 ) {
        //     console.log("------------------------")
        //     for( const intersect of intersects ) {
        //         //intersect.object.material.color.set(0xffffff)
        //         console.log(intersect.object)
        //     }
        // }

        const pushOffsetY = -0.0
        const force = 5
        if( keyEvents.isDown('d') ) {
            const right = new THREE.Vector3();
            right.setFromMatrixColumn(global.camera.matrixWorld, 0); // Extracts the first column (right vector)
            right.y = 0
            right.normalize()
            for( const object of this.objectList ) {
                object.body.wakeUp()
                const pushPos = object.body.position
                pushPos.y += pushOffsetY
                object.body.applyForce(new CANNON.Vec3(force * right.x, force * right.y, force * right.z), pushPos)
            }
        }
        if( keyEvents.isDown('a') ) {
            const right = new THREE.Vector3();
            right.setFromMatrixColumn(global.camera.matrixWorld, 0); // Extracts the first column (right vector)
            right.y = 0
            right.normalize()
            for( const object of this.objectList ) {
                object.body.wakeUp()
                const pushPos = object.body.position
                pushPos.y += pushOffsetY
                object.body.applyForce(new CANNON.Vec3(-force * right.x, -force * right.y, -force * right.z), pushPos)
            }
        }
        if( keyEvents.isDown('w') ) {
            const forward = new THREE.Vector3();
            global.camera.getWorldDirection(forward);
            forward.y = 0
            forward.normalize()            
            for( const object of this.objectList ) {
                object.body.wakeUp()
                const pushPos = object.body.position
                pushPos.y += pushOffsetY
                object.body.applyForce(new CANNON.Vec3(force * forward.x, force * forward.y, force * forward.z), pushPos)
            }
        }
        if( keyEvents.isDown('s') ) {
            const forward = new THREE.Vector3();
            global.camera.getWorldDirection(forward);
            forward.y = 0
            forward.normalize()
            for( const object of this.objectList ) {
                object.body.wakeUp()
                const pushPos = object.body.position
                pushPos.y += pushOffsetY
                object.body.applyForce(new CANNON.Vec3(-force * forward.x, -force * forward.y, -force * forward.z), pushPos)
            }
        }

        if( keyEvents.isPressed('q') ) {
            if( Math.random() > 0.5 )
                this.createBox(randomRange(0.25,1))
            else
                this.createSphere(randomRange(0.25,1))
        }

        this.timer += deltaTime
        // if( this.timer >= 0.25 ) {
        //     this.createSphere(randomRange(0.25,1))
        //     this.timer = 0
        // }

        this.world.step(1/60, deltaTime, 3)
        for( const object of this.objectList ) {
            object.mesh.position.copy(object.body.position)
            object.mesh.quaternion.copy(object.body.quaternion)
        }
    }

    onClickEvent(event) {
        console.log("Physics Lesson Click Event", event)
        global.raycaster.setFromCamera(global.mouseNorm, global.camera)
        const intersects = global.raycaster.intersectObjects(global.scene.children)
        if( intersects.length > 0 ) {
            console.log("------------------------")
            for( const intersect of intersects ) {
                //intersect.object.material.color.set(0xffffff)
                console.log(intersect.object)
            }
        }
    }

    reset() {
        for( const object of this.objectList ) {
            object.body.removeEventListener('collide', this.playHitSfx)
            this.world.removeBody(object.body)
            global.scene.remove(object.mesh)
        }
        this.objectList = []
    }
}

//
// The Lessons
//
const lessons = [
    //new LoadingModelsLesson(),
    new PhysicsLesson(),
    // new ScrollingLesson(),
    // new ParticleLesson(),
    // new ParticleLesson2()
]




//
// Main Loop
//
let time = global.clock.getElapsedTime()
global.updateViewportSize()
const engineUpdate = () => {
    keyEvents.update()
    if( keyEvents.isPressed('h') ) {
        global.gui.show(global.gui._hidden ? true : false)
    }

    // clock.getDelta() messes things up... DON'T USE IT
    const currentTime = global.clock.getElapsedTime()
    const deltaTime = Math.min(currentTime - time, 1)
    time = currentTime

    if (global.orbitCtrl !== null)
        global.orbitCtrl.update()

    for (const lesson of lessons) {
        lesson.update(time, deltaTime)
    }

    global.renderer.render(global.scene, global.camera)
    requestAnimationFrame(engineUpdate)
}
engineUpdate()
